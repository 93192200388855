.patient-visits {
  background-color: #f7f8f9;
}

.overview-title {
  background-color: #f7f8f9;
  margin-top: 20px;
}

.overview-title h3 {
  color: #1a1a1a;
}

.patient-visits-wrapper {
  margin: 20px 50px;
  background-color: #fcfcfc;
  border-radius: 8px;
  overflow: hidden;
}

th,
td {
  border-top: none !important;
  color: #1a1a1a !important;
  font-size: 18px;
}

.view-link {
  background-color: #d4e9eb !important;
  border: none;
  color: #fcfcfc;
  font-size: 18px;
  padding: 8px 30px;
}

.view-link:hover,
.view-link:visited {
  color: #fcfcfc;
}

.btn-filled {
  background-color: #04203d;
  color: white;
  margin-right: 10px !important; /* Adds space between the buttons */
  padding: 6px 14px !important; /* Adjust padding as needed */
  font-size: 0.9rem !important;
  border-radius: 10px;
}

.btn-outline {
  background-color: transparent;
  color: #04203d;
  border: 2px solid #04203d !important;
  margin-right: 10px; /* Adds space between the buttons */
  padding: 6px 14px !important; /* Adjust padding as needed */
  font-size: 0.9rem !important;
  border-radius: 10px;
}
