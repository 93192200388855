/* Container styles */
.info-container {
  padding-bottom: 20px;
}

.personal-info {
  /* background-color: #f0f0f0; */
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.info-wrapper {
  display: flex;
  flex-direction: column;
}

.info {
  margin: 5px 0;
}

/* Table styles */
.table-container {
  margin-top: 15px;
}

.mui-table {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Button styles */
.button {
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
}

.btn-default {
  background-color: #04203d;
  color: #ffffff;
  margin-right: 10px; /* Adds space between the buttons */
  padding: 6px 14px !important; /* Adjust padding as needed */
  font-size: 0.9rem;
  border-radius: 10px;
}

.btn-default:hover {
  background-color: #0056b3;
}

/* Add styles for a button within a link wrapper */
.link-button-wrapper {
  display: inline-block;
  text-decoration: none; /* Removes underline from links */
}

.btn-danger {
  background-color: #ff4d4d;
  color: #ffffff;
  border-radius: 10px;
}

.btn-danger:hover {
  background-color: #e33e33;
}

/* IconButton styles */
.icon-button {
  transition: background-color 0.2s ease-in-out;
}

.icon-button:hover {
  background-color: #eeeeee;
}

/* Typography */
.typography-h6 {
  font-weight: bold;
}

/* Collapse Box styles */
.collapse-box {
  margin: 1rem 0;
}

/* ToastContainer styles */
.toast-container {
  font-size: 0.875rem;
}

/* Other utility styles */
.text-right {
  text-align: right;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}
