.col-form-label{
    color: #000;
font-family: IBM Plex Sans;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.8px;
text-transform: capitalize;
}

.control-css{
    border-radius: 5px !important;
}
.mar-r{
    margin-right: 13px;
}

@media (max-width:437px){
    .control-css{
        width: 100%;
    }
    .mar-r{
        margin-bottom: 17px;
    }
  
}
@media (max-width:991px){
    .width-side{
        width: 100% !important;
        
    }
    .width-container{
        width: 100% !important;
        margin-left: unset;
        margin-right: unset;
    }
}
.error {
    color: #fc8181;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 0.25rem;
  }
input.input-error,
select.input-error {
  border-color: #fc8181;
}

button:disabled {
  opacity: 0.35;
}

.width-side{
    width: 248px;

}
.width-container{
    width: 248px;
    margin-left: auto;
    margin-right: 0;
}