.recording-list {
    padding: 10px 50px;
    background-color: #F7F8F9;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh; 
}
  
.centered-text {
    text-align: center;
}
