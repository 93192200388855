.list-table {
  background-color: #fcfcfc;
  border-radius: 10px;
  /* overflow-x: auto; */
  /* height: calc(100vh - 165px) !important; */
  overflow-y: auto;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}

.table-item img {
  margin-left: 10px;
}

.table-item {
  text-transform: capitalize;
}

.list-view-item {
  text-transform: capitalize;
}

.options {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.hover-options {
  display: flex;
  gap: 10px;
  align-items: center;
  opacity: 0;
  transition: all 0.15s;
}

.dot-menu-icon {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.dot-menu {
  position: absolute;
  top: 30px;
  right: 10px;
  width: 220px;
  background-color: white;
  border: 1px solid #d0d3d4;
  z-index: 10;
  padding: 10px 0;
}

.divider-item {
  padding-bottom: 5px;
  border-bottom: 1px solid #d0d3d4;
  margin-bottom: 5px;
}

p {
  margin: 0;
}

.head {
  opacity: 0;
}

@media (max-width: 1450px) {
  .table-item,
  .data,
  .options {
    width: 161px;
  }
}

@media (max-width: 940px) {
  .list-table {
    overflow-x: auto;
  }

  .checkbox {
    width: 50px;
  }
}

@media (max-width: 880px) {
  .list-table {
    height: calc(100% - 160px);
  }
}
