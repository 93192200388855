.notifications-container {
  position: relative;
  display: inline-block;
}

.notifications-icon-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifications-icon {
  width: 24px;
  height: 24px;
}

.notifications-badge {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 8px;
  height: 8px;
  background-color: #ef4444;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

.notifications-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: -10px;
  width: 320px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid #e5e7eb;
  z-index: 1000;
}

/* Add a small triangle/arrow at the top of the dropdown */
.notifications-dropdown::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 15px;
  width: 10px;
  height: 10px;
  background: white;
  transform: rotate(45deg);
  border-left: 1px solid #e5e7eb;
  border-top: 1px solid #e5e7eb;
}

.notifications-header {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
}

.notifications-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.notifications-content {
  max-height: 384px; /* 96rem */
  overflow-y: auto;
}

.notifications-empty {
  text-align: center;
  color: #6b7280;
  padding: 16px;
}

.notifications-list {
  display: flex;
  flex-direction: column;
}

.notification-item {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  transition: background-color 0.2s ease;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item:hover {
  background-color: #f9fafb;
}

.notification-item.unseen {
  background-color: #eff6ff;
}

.notification-message {
  margin: 0 0 4px 0;
  font-size: 14px;
}

.notification-time {
  margin: 0;
  font-size: 12px;
  color: #6b7280;
}

.notifications-footer {
  padding: 8px;
  border-top: 1px solid #e5e7eb;
}

.mark-read-button {
  width: 100%;
  padding: 8px;
  background: none;
  border: none;
  color: #2563eb;
  font-size: 14px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.mark-read-button:hover {
  background-color: #f9fafb;
  color: #1d4ed8;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(239, 68, 68, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0);
  }
}

/* Custom scrollbar for the notifications list */
.notifications-content::-webkit-scrollbar {
  width: 6px;
}

.notifications-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.notifications-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.notifications-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
