 .section__Jobs-container {
    /* margin-top: 50px;
    max-width: 700px; */
    position: relative;
  }
  
  .section__Jobs-container span {
    border-left: 2px solid#64ffda;
    font-size: 12px;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 15px;
    display: inline-block;
    width: 2px;
    height: 42px;
    transition: transform 0.5s;
  }
  
  .section__Jobs-container span.index1-chosen {
    transform: translateY(0px);
  }
  .section__Jobs-container span.index2-chosen {
    transform: translateY(42px);
  }
  .section__Jobs-container span.index3-chosen {
    transform: translateY(84px);
  }
  
  .section__Jobs-styledTabList li {
    margin: 25px 0;
    /* max-width: 130px; */
  }
  
  .section__Jobs-styledTabList {
    padding: 0;
    list-style-type: none;
  }
.ac-sett{
  color: #1A1A1A;
  font-family: IBM Plex Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: capitalize;
}
.bd-bg{

  border-radius: 8px;
  background: #FCFCFC;
}
.h-70vh{
  min-height: 70vh;
}
.margins-4{
  margin: 0 0 0 3rem !important;
}
@media  (max-width:991px){
.margins-4{
  margin:20px 0 0 0 !important
}
}