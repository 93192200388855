.main-bg {
  background: black !important;
}

input:focus,
button:focus {
  border: 1px solid black !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: black !important;
  border-color: black !important;
}

.card,
.btn,
input {
  border-radius: 0 !important;
}
.login-main {
  background-color: #f7f8f9;
  min-height: 100vh;
}

.password-field,
.confirm-password-field {
  position: relative;
}

.reset > .eye-icon {
  top: 50px;
}

.eye-login {
  top: 50px !important;
}
.eye-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  margin-right: 2px;
  right: 30px;
  top: 45px;
  cursor: pointer;
  overflow: hidden;
  z-index: 100;
}

.login-main .content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.login-form-input {
  padding-right: 50px !important;
  /* padding: 15px !important; */
  border-radius: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.login-form-select {
  padding: 40px !important;
}

.login-form-label {
  color: #1a1a1a;
  font-family: IBM Plex Sans;
  margin-bottom: 0.7rem !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.per-padding {
  padding: 5% !important;
}
.card-border-radius {
  border-radius: 14px !important;
  border: 2px solid #f5f5f5 !important;
}
/* .bg-light-grey{
    background: #f8f8f8;
    height: 100vh;
    display: flex;
    align-items: center;
  
  } */
.btn-style {
  padding: 10px 12px !important;
  background-color: #092540 !important;
  font-size: 8px !important;
  text-transform: uppercase;
  border-radius: 12px !important;
}
.btn-style b {
  color: #fff;
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
}
.pass-forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forgot-text {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.forgot-text a {
  color: #092540 !important;
}

@media (max-width: 991px) {
  .eye-icon {
    top: 50px;
  }
}

@media (max-width: 575px) {
  .logo-image-class {
    margin-left: 0rem !important;
  }
  /* .div-image{
        display: flex;
        justify-content: center;
      } */
}

.form-group-new {
  display: block;
  margin-bottom: 15px;
}

.form-group-new input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group-new label {
  position: relative;
  cursor: pointer;

  color: #1a1a1a;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form-group-new label:before {
  content: "";
  background-color: transparent;
  border: 2px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group-new input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* ----------------- */
.logo-image-class {
  object-fit: contain;
  margin-bottom: 12px;
  margin-left: 3rem;
}

.card-title h2 {
  color: #1a1a1a;
  font-family: IBM Plex Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bottom-text {
  color: #092540;
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.light-bold {
  font-weight: 500;
  font-size: 18px;
}
.bottom-text a {
  color: #092540;
  font-weight: 500 !important;
}

.wider-card {
  max-width: 90%; /* Adjust this value as needed */
  margin: auto; /* Center the card within its container */
}
