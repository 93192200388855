.section__Jobs-buttonCompany {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    /* border-left: 2px solid #8892b0; */
    outline: none;
    font-family: "Roboto Mono", monospace;
    font-size: 13px;
    height: 37px;
  
    text-align: left;
    padding: 0  !important;
    white-space: nowrap;
width: fit-content !important;




    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    /* border-left: 2px solid #8892b0; */
    outline: none;
    font-size: 19px;

    width: 100%;
    text-align: left;
    padding: 0 20px;
    white-space: nowrap;
    color: #000;
    font-family: IBM Plex Sans;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    text-transform: capitalize;
  }
  
  .section__Jobs-buttonCompany:hover,
  .section__Jobs-buttonCompany:focus {
    /* background-color: #172a45; */
    border: none;
    border-bottom: 2px solid #092540 !important;
    outline: none !important;
    box-shadow: none !important;

  }

  .section__Jobs-buttonCompany:focus{
    border: 0 !important;
  }
  
  .section__Jobs-buttonCompany:hover,
  .section__Jobs-buttonCompany:active,
  .section__Jobs-buttonCompany:focus ,
  .section__Jobs-buttonCompany:focus-visible 
  {
 
    outline: none !important;
    box-shadow: none !important;
    border: none;
    border-bottom: 2px solid #092540 !important;

  }
  
  