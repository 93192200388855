.main-bg {
  background: black !important;
}
body {
  font-family: IBM Plex Sans;
}
input:focus,
button:focus {
  border: 1px solid black !important;
  box-shadow: none !important;
}
.form-check-input:checked {
  background-color: black !important;
  border-color: black !important;
}
.card,
.btn,
input {
  border-radius: 0 !important;
}
/* ======================= */
header {
  background-color: #fcfcfc;
}
/* .logo-image-class{
    width: 68%;
    object-fit: contain;
}
.logo-image-class {
    object-fit: contain;
    margin-bottom: 12px;
    margin-left: 3rem;
} */
.header-inner {
  display: flex;
  flex-grow: 1;
  flex-direction: row-reverse;
}
.user-icon {
  position: absolute;
  right: 12px;
  top: 4px;
}
.logo-image-class {
  width: 160px;
  object-fit: contain;
}
.div-image {
  margin-left: 15px;
}
.div-image .logo-image-class {
  width: 232px;
  object-fit: contain;
}
.login-main {
  background-color: #f7f8f9;
  min-height: 100vh;
  height: 100%;
}
.login-main .container,
.login-main .container .row,
.login-main .container .row .col {
  height: 100%;
}
.login-main .content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 30px 0;
}
.login-form-input {
  padding: 15px !important;
  border-radius: 10px !important;
}
.login-form-label {
  color: #1a1a1a;
  font-family: IBM Plex Sans;
  margin-bottom: 0.7rem !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.per-padding {
  padding: 5% !important;
}
.card-border-radius {
  border-radius: 14px !important;
  border: 2px solid #f5f5f5 !important;
}
.btn-style {
  padding: 15px !important;
  border-radius: 10px !important;
  background-color: #092540 !important;
  font-size: 20px !important;
  text-transform: uppercase;
}
.btn-style b {
  color: #fff;
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
}
.forget-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forgot-text {
  color: #092540;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form-group-new {
  display: block;
  margin-bottom: 15px;
}

.form-group-new input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group-new label {
  position: relative;
  cursor: pointer;
  color: #1a1a1a;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form-group-new label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group-new input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* ----------------- */

.card-title h2 {
  color: #1a1a1a;
  font-family: IBM Plex Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bottom-text {
  color: #092540;
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.light-bold {
  font-weight: 500;
  font-size: 18px;
}

/* Patient css */

.pateint-heading {
  margin-right: 38px;
}
.space-white {
  white-space: nowrap !important;
}
.px-large-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.bg-light-grey-rad {
  border-radius: 12px;
  background: #e1e5e9;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset;
  padding: 5px 6px;
  display: inline-flex;
  gap: 5px;
}
.bt-hov {
  padding: 2px !important;
}
.bt-hov:hover {
  background: white !important;
  border: 2px solid white !important;
}
.bt-hov:focus {
  background: white !important;
  border: 2px solid white !important;
}
.bt-hov:focus,
.bt-hov.active {
  background: white !important;
  border: 2px solid white !important;
  border-radius: 4px !important;
}
.bg-light-pa {
  background-color: #f7f8f9;
}
.border-light-g {
  border-radius: 8px !important;
}
.label-mar {
  margin-left: 44px;
  font-weight: 400;
  display: flex;
  color: #000;
  font-size: 20px;
  align-items: center;
}
.label-mar svg {
  margin-left: 10px;
  display: flex;
  align-items: center;
  margin-top: 7px;
}
.th-hov {
  padding-bottom: 11px !important;
  font-weight: 400;
  text-transform: capitalize;
}
.th-hov svg {
  margin-left: 12px;
}
tr {
  border-bottom: 1px solid white !important;
}
.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #d9edee !important;
}
tr:hover .space-up {
  visibility: visible;
}
td .space-up {
  visibility: hidden;
}
td .space-up svg {
  cursor: pointer;
}
.border-t {
  border-bottom: 1px solid #ddd !important;
}
td {
  white-space: nowrap;
}
.patient-list .container {
  height: 100%;
}
.patient-list .container .table-main {
  min-height: 80vh;
}
.patient-list .container .table-main .form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
  border-radius: 4px !important;
}
.patient-list .search-box .form-control {
  min-width: 328px;
  border-radius: 8px !important;
  border-color: #d0d3d4;
  background-color: #fff;
}
.patient-list .search-box .form-control:focus {
  box-shadow: none;
  border: 1px solid #000;
}
.right-side {
  display: flex;
  align-items: center;
}
.right-side .pateint-heading .bt-hov {
  border: 2px solid transparent;
}

.drop-b:after {
  display: none !important;
}
.div-tab {
  background: white;
  min-height: 75%;
  border-radius: 11px;
}
.item-dro {
  padding: 8px 15px !important;
}
.item-dro span svg {
  width: 20px;
}
.item-dro:hover {
  background-color: #d9edee !important;
}
.item-dro.active {
  background-color: #d9edee !important;
}
.custom-dropdown .ul-man {
  min-width: 220px;
  border-radius: 4px;
  border: 1px solid rgb(208, 211, 212);
}
.nav-style {
  margin-right: 12px;
  font-size: 20px;
  padding: 0 20px;
  font-style: normal;
  font-weight: 400;
}
.nav-style:hover {
  background: #d9edee !important;
  border-bottom: 3px solid #042325;
}
.nav-style::after {
  display: none !important;
}

.navbar-brand {
  margin-right: 0 !important;
}
.item-dro.active {
  background-color: #d9edee !important;
  color: black !important;
}
.item-dro:hover {
  background-color: #d9edee !important;
  color: black !important;
}

/* End Patient Setting */

.nav-style::after {
  display: none !important;
}
.nav-style.nav-link {
  margin-right: 12px;
  font-size: 20px;
  padding: 0 20px;
  font-style: normal;
  font-weight: 400;
  border-bottom: 2px solid transparent;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
  outline: 0;
}
.nav-style.nav-link:focus,
.nav-style.nav-link:hover {
  background: #d9edee;
  border-bottom: 2px solid #092540;
  box-shadow: none;
  outline: 0;
}
.custom-style.navbar-expand-lg .navbar-nav .dropdown-menu {
  margin-top: 10px;
  min-width: 220px;
}
.custom-style ul li ul li a {
  padding: 13px 20px;
  /* background: #D9EDEE; */
  background-color: white;
}

.custom-style ul li ul li a:hover,
.custom-style ul li ul li a:focus,
.custom-style ul li ul li a.active {
  background: #d9edee;
}
.bg-light-pa {
  background-color: #f7f8f9;
}
.text-t {
  color: #1a1a1a;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.px-large-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.nav-set {
  display: flex;
  flex-direction: column;
  border: none !important;
}
.body-s {
  border-radius: 8px;
  border: 1px solid #d0d3d4;
  border-radius: 5px;
  border-radius: 8px;
  background: #fcfcfc;
}
.nav-set .nav-item .nav-link {
  width: fit-content;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: capitalize;
  color: #000;
  padding: 0;
  outline: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
.nav-set .nav-item .nav-link:hover,
.nav-set .nav-item .nav-link:focus,
.nav-set .nav-item .nav-link.active {
  border-bottom: 2px solid #092540;
  padding: 0;
  outline: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  text-decoration: none;
}
.btn-primary {
  background-color: #092540 !important;
  border: 1px solid #092540 !important;
  color: #fff;
  text-align: center;

  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;

  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.btn-primary:hover {
  background-color: white !important;
  border: 1px solid #092540 !important;
  color: #092540 !important;
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.btn-outline-primary {
  white-space: nowrap;
  background-color: white !important;
  border: 1px solid #092540 !important;
  color: #092540 !important;
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.btn-outline-primary:hover {
  background-color: #092540 !important;
  border: 1px solid #092540 !important;
  color: white !important;
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.btn-po {
  position: absolute;
  bottom: 19px;
  right: 35px;
}
.m-right {
  margin-right: 23px;
}
.account-setting .left {
  width: 360px;
  max-width: 360px;
  padding: 25px 20px;
  margin-right: 30px;
}
.account-setting .left ul li {
  padding: 20px 15px;
}
.account-setting .left ul li a {
  border-bottom: 2px solid transparent;
}
.account-setting .left ul li a.active,
.account-setting .left ul li a:hover {
  border-bottom: 2px solid #092540;
}
.account-setting .right .tab-content .tab-pane input {
  min-width: 440px;
  width: 100%;
  border-radius: 8px !important;
  border: 1px solid #d0d3d4;
  background: #fcfcfc;
}
.account-setting .right .tab-content .tab-pane input:hover,
.account-setting .right .tab-content .tab-pane input:focus {
  box-shadow: none;
  border-color: #000;
}

.navbar-brand {
  margin-right: 0 !important;
}
.item-dro.active {
  background-color: #d9edee !important;
  color: black !important;
}
.item-dro:hover {
  background-color: #d9edee !important;
  color: black !important;
}
.user-icon {
  padding: 0;
  margin: 0;
  list-style: none;
}
.user-icon li {
  height: 53px;
  width: 53px;
  background-color: #d9d9d9;
  border-radius: 50%;
}
.user-icon li img {
  height: 53px;
  width: 53px;
  object-fit: cover;
}

/* ========================= */

@media (max-width: 1199.9px) {
  .forgot-text {
    font-size: 16px;
  }
  .login-main .container {
    /* display: flex; */
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100% !important;
  }
  .login-main {
    height: auto;
    /* min-height: auto; */
  }
  .login-main .container,
  .login-main .container .row,
  .login-main .container .row .col,
  .login-main .content {
    height: auto;
  }
  .label-mar {
    margin-left: 25px;
  }
}

@media (max-width: 991px) {
  .body-s {
    margin-bottom: 16px;
  }
  .btn-po {
    position: unset;
    margin-bottom: 18px;
  }
  .account-setting .left,
  .account-setting .right {
    width: 100%;
    max-width: 100%;
    flex: 0 0 auto;
  }
  .account-setting .right .tab-content .tab-pane input {
    width: 100%;
    min-width: 300px;
  }
  .user-icon {
    position: relative;
    right: auto;
    top: auto;
  }
  .div-space .d-flex {
    flex-wrap: wrap;
  }
  .right-side {
    align-items: flex-end;
  }
  .label-mar {
    margin-left: 20px;
  }
}
@media (max-width: 767.9px) {
  .div-space {
    flex-wrap: wrap;
    gap: 15px;
  }
}
@media (max-width: 575px) {
  .px-large-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .btn-po {
    position: unset;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .m-right {
    margin-right: 0px;
    margin-bottom: 12px;
  }

  /* login responsive */

  .logo-image-class {
    margin-left: 0rem !important;
  }
  .account-setting .right .tab-content .tab-pane .row {
    flex-direction: column;
  }
  .account-setting .right .tab-content .tab-pane input {
    width: 100%;
    min-width: 100%;
  }

  /* patient-responsive */
  .div-space {
    flex-direction: column !important;
  }
  .pateint-heading {
    margin-right: 10px;
  }
  .px-large-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  /* .right-side{
        margin-left: auto;
        margin-bottom: 18px;
    } */
  .navbar-brand {
    margin-right: 0 !important;
  }
  .mar-brand {
    margin-right: 0;
  }
}

@media (max-width: 375px) {
  .padd-0 {
    padding: 0 !important;
  }
}
.account-setting {
  height: 100vh;
}
.color-active.active {
  background-color: white !important;
}
