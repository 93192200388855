/**************************** Custom Font Families --- */
@font-face {
  font-family: "Lato-Regular";
  src: url(../fonts/Lato-Regular.ttf);
}
@font-face {
  font-family: "Lato-Medium";
  src: url(../fonts/Lato-Medium.ttf);
}
@font-face {
  font-family: "Lato-Light";
  src: url(../fonts/Lato-Light.ttf);
}
@font-face {
  font-family: "Lato-Bold";
  src: url(../fonts/Lato-Bold.ttf);
}
@font-face {
  font-family: "Lato-Thin";
  src: url(../fonts/Lato-Thin.ttf);
}
@font-face {
  font-family: "Lato-Black";
  src: url(../fonts/Lato-Black.ttf);
}
/**************************** End Custom Font Families --- */

/******************************************** General CSS --- */
body {
  font-family: "Lato-Bold";
  font-size: 18px;
  color: #000000;
  width: 100%;
}
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato-Bold";
}
.h1,
h1 {
  font-size: 48px;
  font-family: "Lato-Bold";
}
.h2,
h2 {
  font-size: 50px;
  font-family: "Lato-Bold";
}
.h3,
h3 {
  font-size: 35px;
}
.h6,
h6 {
  font-size: 14px;
  letter-spacing: 2px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.full-img {
  width: 100%;
}
.mt-120 {
  margin-top: 120px;
}
.mw-300 {
  max-width: 300px;
  width: 100%;
}
.mt-50 {
  margin-top: 50px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-150 {
  margin-top: 150px;
}
.p-relative {
  position: relative;
}
.mb-150 {
  margin-bottom: 150px;
}
.pt-15 {
  padding-top: 15px;
}
/* --- End General CSS --- */

/***************************** Header **/
.header-bg {
  width: 100%;
  height: 71%;
  overflow: hidden;
  position: absolute;
  margin: 0;
}
.header-bg:after {
  content: "";
  width: 142%;
  height: 142%;
  position: absolute;
  left: -20%;
  top: -75%;
  transform: rotate(-6deg) translate(0%, 0);
  background-image: linear-gradient(to right, #d9e9ea, #80b5b8);
  overflow: hidden;
}
.navbar-expand-lg .navbar-nav .nav-link {
  /* color: #fff; */
  color: black;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.navbar-expand-lg .navbar-nav .nav-link:hover {
  /* text-decoration: underline; */
}
a.login-btn {
  background: rgb(244 244 241 / 70%);
  color: #9bc4c6;
  padding: 8px 50px;
  border-radius: 10px;
  margin-left: 40px;
}
a.login-btn:hover {
  background: #f4f4f1;
}
.navbar-toggler-icon {
  background-image: url(../img/menu.svg);
}
section.platform {
  background-image: linear-gradient(to right, #d9e9ea, #80b5b8);
  position: relative;
  padding-bottom: 350px;
}
section.platform::before {
  content: "";
  background-color: #fff;
  position: absolute;
  top: -1px;
  width: 100%;
  height: 45%;
  background: conic-gradient(
    at 0% 0%,
    transparent 102deg,
    white 0,
    #ffffff 271deg,
    transparent 0
  );
  transform: rotate(180deg);
}
section.peneeg {
  margin-top: -350px;
}

/*********************************** Founder section **/
.teamtitle {
  margin-bottom: 45px;
}
h3.member {
  margin-top: 30px;
}
section.founder-sec {
  background-image: linear-gradient(to right, #d9e9ea, #80b5b8);
  position: relative;
  padding-bottom: 100px;
  padding-top: 200px;
}
section.founder-sec::before {
  content: "";
  background-color: #fff;
  position: absolute;
  top: -1px;
  width: 100%;
  height: 20%;
  background: conic-gradient(
    at 0% 100%,
    transparent 80deg,
    white 0,
    #ffffff 90deg,
    transparent 0
  );
  transform: rotate(180deg);
}
.team-members .image-effect .social-box {
  position: absolute;
  left: 0px;
  top: -10%;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  margin-top: -12px;
  text-align: center;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
}
.team-members:hover .image-effect .social-box {
  opacity: 1;
  top: 42%;
}
.team-members .image-effect .social-box a {
  color: #fff !important;
}
.image-effect {
  overflow: hidden;
  border-radius: 300px;
  position: relative;
  width: fit-content;
  margin: auto;
}
.team-members .image-effect:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  background-color: rgba(0, 186, 190, 0.6);
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
  -webkit-transform: perspective(400px) rotateY(-90deg);
  -moz-transform: perspective(400px) rotateY(-90deg);
  -ms-transform: perspective(400px) rotateY(-90deg);
  -o-transform: perspective(400px) rotateY(-90deg);
  transform: perspective(400px) rotateY(-90deg);
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -ms-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.team-members:hover .image-effect:before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateY(0deg);
  -moz-transform: perspective(400px) rotateY(0deg);
  -ms-transform: perspective(400px) rotateY(0deg);
  -o-transform: perspective(400px) rotateY(0deg);
  transform: perspective(400px) rotateY(0deg);
}
/************************** Collaborators **/
.logos-partners {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.logos-partners img {
  max-height: 75px;
  padding: 0px 30px;
  max-width: 100%;
  margin-bottom: 20px;
}
/****************************** Cintact Us **/
.contact-us form label {
  width: 100%;
  font-size: 1.5em;
}
.contact-us form input#name,
.contact-us form input#email {
  width: 100%;
  height: 60px;
  border: 0;
  outline: 0px !important;
  padding: 10px 30px;
  border-radius: 10px;
  background: #f4f4f1;
  box-shadow: 0px 0px 5px #0000000d;
}
.contact-us form textarea#message {
  width: 100%;
  border: 0;
  outline: 0px !important;
  padding: 20px 30px;
  border-radius: 10px;
  background: #f4f4f1;
  box-shadow: 0px 0px 5px #0000000d;
}
.contact-us form {
  background: rgb(244 244 241 / 70%);
  padding: 50px;
  border-radius: 70px;
  box-shadow: 0px 0px 15px #5a5a5a2e;
}
input.form-btn {
  padding: 12px 60px;
  font-size: 1.5em;
  border: 0px;
  border-radius: 15px;
  background: #abcdcf;
  color: #fff;
  font-family: "Lato-Regular";
  -moz-box-shadow: inset 0 0 20px #00000026;
  -webkit-box-shadow: inset 0 0 20px #00000026;
  box-shadow: inset 0 0 20px #00000026;
  cursor: pointer;
}
.formtitle {
  margin-bottom: 30px;
}
input.form-btn:hover {
  -moz-box-shadow: inset 0 0 10px #00000026;
  -webkit-box-shadow: inset 0 0 10px #00000026;
  box-shadow: inset 0 0 10px #00000026;
}

/***************** Media Queries **/
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    /* color: #fff; */
    color: black;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
@media (max-width: 991px) {
  li.nav-item {
    border-bottom: 1px solid rgb(244 244 241 / 25%);
    background: #8fbdc0;
  }
  .navbar-text {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    background: #cde2e3;
  }
  a.login-btn {
    background: #80d7d8;
    color: #ffffff;
    padding: 8px 50px;
    border-radius: 0px;
    margin-left: 0;
    width: 100%;
    display: block;
  }
  .navbar-toggler {
    outline: 0 !important;
    filter: invert(1);
  }
  section.platform::before {
    height: 28%;
  }
  .mt-150 {
    margin-top: 100px;
  }
  section.founder-sec {
    padding-top: 120px;
  }
}
@media (max-width: 767px) {
  input.form-btn {
    padding: 10px;
    font-size: 1.2em;
    width: 100%;
  }
  .contact-us form {
    padding: 30px;
    border-radius: 40px;
    box-shadow: 0px 0px 15px #5a5a5a2e;
  }
  .contact-us form label {
    font-size: 1.1em;
  }
  .mt-120 {
    margin-top: 30px;
  }
  .header-bg:after {
    height: 114%;
  }
  section.platform {
    padding-bottom: 150px;
    padding-top: 40px;
  }
  section.platform::before {
    height: 15% !important;
  }
  section.peneeg {
    margin-top: -140px;
  }
  section.founder-sec {
    padding-top: 80px !important;
  }
}

/************************************************************************************/
/********************************* LOGIN PAGE **************************************/
.login-bg {
  width: 100%;
  height: 95vh;
  overflow: hidden;
  position: absolute;
}
.boxes-in-bg {
  margin: 0;
  width: 142%;
  height: 142%;
  position: absolute;
  left: -20%;
  top: -78%;
  transform: rotate(-10.33deg);
  background: rgba(0, 107, 111, 0.15);
}
.boxes-in-bg:after {
  content: "";
  width: 444px;
  height: 40px;
  position: absolute;
  left: 5%;
  top: 100%;
  background: rgba(0, 186, 190, 0.5);
  overflow: hidden;
}
.boxes-in-bg:before {
  content: "";
  width: 444px;
  height: 40px;
  position: absolute;
  right: 15%;
  top: 97%;
  overflow: hidden;
  background: rgba(1, 149, 153, 0.75);
}

.login-page .row.align-items-center {
  height: calc(100vh - 50px);
}
.login-form {
  padding: 35px 24px 1px 36px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 10px 10px 10px rgb(0 0 0 / 10%);
  border-radius: 20px;
}
.form-title h3 {
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #000000;
  font-family: "Lato-Medium";
  margin-bottom: 30px;
}
.login-form label {
  font-family: "Lato-Regular";
  font-size: 20px;
  line-height: 15px;
  color: #000000;
  display: block;
}
.login-form input[type="text"],
.login-form input[type="email"],
.login-form input[type="password"] {
  width: 100%;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 15px;
  outline: 0px !important;
  padding: 15px 25px;
}
.password-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.password-label label a {
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  color: #006b6f;
}
.login-form input[type="submit"] {
  width: 100%;
  height: 63px;
  background: rgba(0, 107, 111, 0.5);
  border-radius: 50px;
  border: 0px;
  font-family: "Lato-Bold";
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 30px;
  cursor: pointer;
}
.login-form input[type="submit"]:hover {
  background: rgba(0, 107, 111, 0.7);
}
.not-user {
  font-size: 24px;
  line-height: 1px;
  font-family: "Lato-Regular";
  text-align: center;
  color: #000000;
  margin-bottom: 37px;
}
.not-user a {
  color: #000000;
}
.not-user a:hover {
  color: #006b6f;
}
.login-logo {
  margin-bottom: 30px;
}
.form-group {
  margin-bottom: 1.5rem;
}
.login-footer-menu ul {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  padding-left: 0px;
}
.login-footer-menu ul li a {
  font-family: "Lato-Regular";
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.login-footer-menu ul li a:hover {
  color: #006b6f;
}
.login-logo img {
  max-width: 100%;
}

/************ Media Queries **/
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .boxes-in-bg:after {
    width: 18%;
    height: 40px;
    left: 0%;
    top: 100%;
  }
  .boxes-in-bg:before {
    width: 18%;
    right: 20%;
    top: 97%;
  }
}
@media (max-width: 991px) {
  .boxes-in-bg:after {
    left: 0%;
  }
}
@media (max-width: 767px) {
  .boxes-in-bg {
    left: -40%;
    top: -70%;
  }
  .login-page .row.align-items-center {
    height: auto;
    padding: 35px 0px;
  }
  .loginheight {
    height: auto;
  }
  .login-footer-menu ul li a {
    padding: 10px;
    display: block;
  }
  .login-footer-menu ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  .boxes-in-bg:after {
    left: 0%;
    width: 30%;
  }
  .boxes-in-bg:before {
    width: 30%;
    height: 40px;
    right: 10%;
    top: 97%;
  }
  .login-form {
    padding: 35px 20px 10px 20px;
  }
  .form-title h3 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .login-form label {
    font-size: 20px;
  }
  .password-label label a {
    font-size: 16px;
  }
  .login-form input[type="submit"] {
    margin-bottom: 40px;
  }
  .not-user {
    font-size: 20px;
  }
  .login-footer-menu ul li a {
    font-size: 20px;
  }
}

/************************************************************************************/
/********************************* DASHBOARD PAGE **********************************/
.dashboard-header {
  height: 120px;
  background: rgba(0, 107, 111, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashbord-logo {
  padding-left: 38px;
}
.user-img {
  padding-right: 40px;
  padding-top: 60px;
}
.header-search form {
  margin-bottom: 0px;
}
.header-search input[type="search"] {
  height: 67px;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  margin-right: 20px !important;
  margin-left: 20px;
  font-family: "Lato-Medium";
}
.header-search {
  flex-basis: 60%;
}
.header-search input[type="search"]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}
.header-search input[type="search"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}
.header-search input[type="search"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}
#navbarSupportedContent {
  height: inherit;
}
.left-menu-desktop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 60px;
  padding-left: 24px;
  height: -webkit-fill-available;
  align-items: flex-start;
}
ul.left-menu {
  list-style: none;
  padding-left: 0px;
}
ul.left-menu li a {
  font-family: "lato-Regular";
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  color: rgba(38, 98, 131, 0.75);
  margin-bottom: 60px;
}
a.logout {
  font-family: "Lato-Regular";
  font-size: 28px;
  line-height: 34px;
  color: #d93c0b;
}
.dashboard-inner {
  margin-top: 60px;
  margin-bottom: 60px;
}
.overview-mainbox {
  background: rgba(45, 44, 44, 0.1);
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 25px;
  width: 100%;
  padding: 20px 0px;
}
.overview-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
}
.overview-title h3 {
  width: calc(100% - 70px);
  padding-left: 70px;
  font-family: "Lato-Medium";
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: rgba(38, 98, 131, 0.75);
}
.sort-filter {
  font-family: "Lato-Medium";
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  text-align: center;
  display: flex;
  color: #2d2c2c;
}
.sort-filter svg {
  margin-left: 8px;
}
.patient-box.seizing {
  background: rgba(228, 160, 138, 0.6);
  box-shadow: 10px 10px 8px rgb(0 0 0 / 5%);
  border-radius: 25px;
  padding: 26px 30px 20px 30px;
}
/* .mySwiper .swiper-wrapper,*/
.mySwiper .swiper-container {
  width: 100%;
}
.patient-info {
  display: flex;
  width: 100%;
}
.patient-img {
  width: auto;
  height: 75px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 70px;
}
h4.patient-name {
  font-family: "Lato-Regular";
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 0px;
}
.patient-injury {
  font-family: "Lato-Light";
  font-size: 20px;
  line-height: 24px;
  color: #434343;
}
.patient-status {
  font-family: "Lato-Regular";
  font-size: 20px;
  line-height: 24px;
  color: #434343;
}
.patient-report {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 35px;
}
a.full-report {
  background: #d93c0b;
  box-shadow: 2px 2px 12px rgb(0 0 0 / 20%);
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  width: 150px;
  height: 40px;
  padding: 10px;
}
.full-report:hover,
.contact-technician:hover {
  box-shadow: 2px 2px 12px rgb(0 0 0 / 30%);
  color: #ffffff;
}

a.file-report {
  background: #d93c0b;
  box-shadow: 2px 2px 12px rgb(0 0 0 / 20%);
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  width: 130px;
  height: 40px;
  padding: 10px;
  margin-left: auto;
}
.file-report:hover,
.contact-technician:hover {
  box-shadow: 2px 2px 12px rgb(0 0 0 / 30%);
  color: #ffffff;
}
.contact-technician {
  background: #d93c0b;
  color: #ffffff;
  box-shadow: 2px 2px 12px rgb(0 0 0 / 20%);
  border-radius: 10px;
  width: 150px;
  height: 40px;
  padding: 10px;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}
.slide-boxes {
  margin-top: 45px;
}
.patient-box.recovering {
  background: rgba(254, 195, 77, 0.6);
  box-shadow: 10px 10px 8px rgb(0 0 0 / 5%);
  border-radius: 25px;
  padding: 26px 28px 20px 28px;
}
.recovering .full-report {
  background: #f98340;
}
.recovering .file-report {
  background: #f98340;
}
.recovering .contact-technician {
  background: #f98340;
}
.patient-box.not-critical {
  background: rgba(50, 205, 50, 0.3);
  box-shadow: 10px 10px 8px rgb(0 0 0 / 5%);
  border-radius: 25px;
  padding: 26px 28px 20px 28px;
}
.not-critical .full-report {
  background: rgba(1, 149, 153, 0.75);
  box-shadow: none;
}
.not-critical .file-report {
  background: rgba(1, 149, 153, 0.75);
  box-shadow: none;
}
.not-critical .contact-technician {
  background: rgba(196, 196, 196, 0.9);
  box-shadow: none;
}
.not-critical .full-report:hover {
  box-shadow: 2px 2px 12px rgb(0 0 0 / 10%);
}
.not-critical .file-report:hover {
  box-shadow: 2px 2px 12px rgb(0 0 0 / 10%);
}
.not-critical .contact-technician:hover {
  box-shadow: 2px 2px 12px rgb(0 0 0 / 10%);
}
.swiper-slide {
  padding: 0px 40px;
  padding-bottom: 50px;
}
.swiper-wrapper {
  height: auto;
}
.swiper-pagination-bullet-active {
  background: rgba(228, 160, 138, 0.6);
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
}
.dashboard-inbox {
  background: rgba(45, 44, 44, 0.1);
  border-radius: 25px;
}
.dashboard-inbox {
  background: rgba(45, 44, 44, 0.1);
  border-radius: 25px;
  padding: 20px;
}
.inbox-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.inbox-title h3 {
  width: calc(100% - 30px);
  text-align: center;
  padding-left: 30px;
  font-size: 32px;
  font-family: "Lato-Medium";
  line-height: 38px;
  color: rgba(38, 98, 131, 0.75);
  margin-bottom: 0px;
}
.inbox-body {
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
}
.message-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Lato-Medium";
  font-size: 22px;
  line-height: 26px;
  color: rgba(38, 98, 131, 0.75);
}
.massage-content {
  font-family: "Lato-Medium";
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #565656;
}
.inbox-message {
  border-bottom: 0.5px dashed #605a5a;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.inbox-body .inbox-message:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.calendar thead {
  font-weight: 600;
  text-transform: uppercase;
}
.calendar tbody {
  color: #7c8a95;
}

.calendar tbody td:hover {
  border: 2px solid #00addf;
}
.calendar td {
  border: 2px solid transparent;
  border-radius: 50%;
  display: inline-block;
  height: 35px;
  line-height: 21px;
  text-align: center;
  width: 35px;
  color: #000000;
}
.calendar .prev-month,
.calendar .next-month {
  color: #cbd1d2;
}
.calendar .prev-month:hover,
.calendar .next-month:hover {
  border: 2px solid #cbd1d2;
}
.event {
  cursor: pointer;
  position: relative;
}
.event:after {
  background: #00babe;
  border-radius: 50%;
  bottom: 3px;
  display: block;
  content: "";
  height: 6px;
  left: 45%;
  position: absolute;
  width: 6px;
}
.event.out:after {
  background: #c4c4c4;
}
.calendar h4 {
  font-family: "Lato-Medium";
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: rgba(38, 98, 131, 0.75);
}
.calender-body {
  display: flex;
  justify-content: space-around;
}
.calendar.one {
  padding-right: 6%;
  border-right: 2px solid #c1c1c1;
}
.calendar-box {
  margin-top: 30px;
}
.mobile-search {
  display: none;
}
@media (min-width: 992px) {
  .left-sidebar {
    flex: 0 0 12.666667%;
    max-width: 12.666667%;
  }
  .right-boxes.col-lg-4 {
    flex: 0 0 37.333333%;
    max-width: 37.333333%;
    padding-right: 40px;
  }
  .left-sidebar .navbar-expand-lg {
    padding: 0;
  }
}
@media only screen and (max-width: 1709px) and (min-width: 1200px) {
  .calender-body {
    flex-direction: column;
    align-items: center;
  }
  .calendar.one {
    padding-right: 0;
    border-bottom: 2px solid #c1c1c1;
    border-right: 0;
    margin-bottom: 30px;
  }
  ul.left-menu li a {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 45px;
  }
  .patient-box {
    padding: 20px 15px 20px 15px !important;
  }
  .patient-info {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .patient-report {
    flex-direction: column;
  }
  .patient-details {
    text-align: center;
  }
  a.full-report {
    margin-bottom: 5px;
    width: 100%;
  }
  a.file-report {
    margin-bottom: 5px;
  }
  .contact-technician {
    width: 100%;
  }
  a.logout {
    font-size: 20px;
    line-height: 24px;
  }
  .swiper-slide {
    padding: 0px 20px 40px 20px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .calender-body {
    flex-direction: column;
    align-items: center;
  }
  .calendar.one {
    padding-right: 0;
    border-bottom: 2px solid #c1c1c1;
    border-right: 0;
    margin-bottom: 30px;
  }
  .patient-box {
    padding: 20px 15px 20px 15px !important;
  }
  ul.left-menu li a {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 45px;
  }
  .dashbord-logo {
    padding-left: 28px;
  }
  .left-menu-desktop {
    padding-left: 14px;
  }
  .user-img {
    padding-right: 30px;
  }
  .right-boxes.col-lg-4 {
    padding-right: 30px !important;
  }
  a.logout {
    font-size: 20px;
    line-height: 24px;
  }
  .patient-overview.col-lg-6 {
    flex: 0 0 46%;
    max-width: 46%;
  }
  .left-sidebar {
    flex: 0 0 16.666667% !important;
    max-width: 16.666667% !important;
  }
  .overview-title h3 {
    padding-left: 0;
    text-align: left;
    font-size: 30px;
  }
  .inbox-title h3 {
    text-align: left;
    padding-left: 0;
    font-size: 30px;
    line-height: 38px;
  }
}
@media (max-width: 1199px) {
  .swiper-slide {
    padding: 0px 15px 30px 15px;
  }
  .patient-box {
    padding: 20px 15px 20px 15px !important;
  }
  .patient-info {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .patient-report {
    flex-direction: column;
  }
  .patient-details {
    text-align: center;
  }
  a.full-report {
    margin-bottom: 5px;
    width: 100%;
  }
  a.file-report {
    margin-bottom: 5px;
  }
  .contact-technician {
    width: 100%;
  }
  h4.patient-name {
    font-size: 24px;
    padding: 5px 0;
  }
  .patient-injury {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .patient-status {
    font-size: 18px;
  }
  .mySwiper .swiper-container {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .dashboard-inner .navbar-toggler {
    filter: invert(0);
    border: 1px solid #000;
  }
  .left-menu-desktop {
    padding-top: 10px;
    padding-left: 0px;
  }
  ul.left-menu {
    display: block;
    width: 100%;
  }
  ul.left-menu li a {
    margin-bottom: 10px;
    padding-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid;
  }
  .overview-mainbox {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .calender-body {
    justify-content: space-evenly;
  }
  .calendar.one {
    padding-right: 12%;
  }
  .dashboard-inner .navbar {
    padding-left: 0px !important;
  }
}
@media (max-width: 767px) {
  .calender-body {
    flex-direction: column;
    align-items: center;
  }
  .calendar.one {
    padding-right: 0;
    border-bottom: 2px solid #c1c1c1;
    border-right: 0;
    margin-bottom: 30px;
  }
  .patient-info {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  a.full-report {
    margin-bottom: 5px;
    width: 100%;
  }
  a.file-report {
    margin-bottom: 5px;
  }
  .contact-technician {
    width: 100%;
  }
  .patient-report {
    flex-direction: column;
  }
  .patient-details {
    text-align: center;
  }
  h4.patient-name {
    font-size: 24px;
    line-height: 28px;
  }
  .patient-injury {
    font-size: 18px;
    line-height: 22px;
  }
  .patient-status {
    font-size: 18px;
    line-height: 22px;
  }
  .patient-box {
    padding: 20px 15px 20px 15px !important;
  }

  .swiper-slide {
    padding: 0px 15px;
    padding-bottom: 30px !important;
  }
  .dashbord-logo {
    padding-left: 15px;
  }
  .user-img {
    padding-right: 15px;
    padding-top: 0px;
  }
  .user-img img {
    max-width: 50px;
  }
  .dashbord-logo img {
    max-width: 120px;
  }
  .header-search form {
    display: none;
  }
  .dashboard-header {
    height: 80px;
  }
  .mobile-search {
    display: block;
    text-align: right;
    padding-right: 10px;
    font-size: 20px;
  }
  .mobile-search a {
    color: #ffffff;
  }
  ul.left-menu li a {
    font-size: 24px;
    line-height: 30px;
  }
  a.logout {
    font-size: 24px;
    line-height: 30px;
  }
  .overview-title {
    padding: 0px 15px;
  }
  .overview-title h3 {
    width: calc(100% - 70px);
    padding-left: 0px;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 0px;
  }
  .inbox-title h3 {
    text-align: left;
    padding-left: 0px;
    font-size: 24px;
    line-height: 28px;
  }
  .calendar h4 {
    font-size: 24px;
    line-height: 28px;
  }
  .dashboard-inner {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media (max-width: 350px) {
  .calendar td {
    height: 32px;
    width: 32px;
  }
}

/************************************************************************************/
/********************************* RECORDING PAGE **********************************/
.ls-patient-name {
  font-family: "Lato-Regular";
  font-size: 26px;
  line-height: 31px;
  color: #000000;
}
.ls-patient-status {
  font-family: "Lato-Regular";
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.ls-dor {
  font-family: "Lato-Regular";
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.left-section-header {
  margin-left: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.25);
}
.review-recording .dashboard-inner {
  margin-top: 20px;
}
.review-recording .left-menu-desktop {
  padding-top: 20px;
}
.ls-patient-status span {
  width: 8px;
  height: 8px;
  background: #b2e1b2;
  display: inline-block;
  border-radius: 10px;
  margin-bottom: 4px;
  margin-left: 10px;
  margin-right: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
  margin-bottom: 0px;
  margin-left: 12px;
  margin-right: 12px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(249, 131, 64, 0.6);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 25%);
}
input:checked + .slider {
  background-color: rgba(217, 11, 11, 0.5);
}
input:focus + .slider {
  box-shadow: 0 0 1px rgba(217, 11, 11, 0.5);
}
input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.rocording-stats-box {
  border: 1px solid rgba(45, 44, 44, 0.25);
  border-radius: 25px;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
}
.display-scale {
  display: flex;
  flex-basis: 34%;
  flex-wrap: wrap;
}
.lef-hff {
  display: flex;
  /* flex-basis: 32%; */
  /* flex-wrap: wrap; */
}
.recording-terms {
  font-family: "Lato-Regular";
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  margin-right: 28px;
  padding: 10px 0px;
}
span.blue-color {
  color: #27aae1;
}
.change-switch {
  display: flex;
  align-items: center;
  font-family: "Lato-Regular";
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  text-align: right;
  padding: 10px 0px;
}
.change-nochange {
  display: flex;
  flex-basis: 30%;
  flex-wrap: wrap;
}
.graph-box {
  border: 1px solid rgba(45, 44, 44, 0.25);
  padding: 15px 10px;
}
.empty-box {
  width: 100%;
  height: auto;
  border: 1px solid rgba(45, 44, 44, 0.25);
  border-radius: 25px;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  display: flex;
}

.zoomButtons {
  justify-content: flex !important;
}

@media (max-width: 767px) {
  .empty-box {
    height: auto;
    display: block;
  }
  button.green-btn {
    width: 100% !important;
    margin-bottom: 10px;
  }

  button.red-btn {
    width: 100% !important;
    margin-bottom: 10px;
  }

  button.grey-btn {
    width: 100% !important;
    margin-bottom: 10px;
  }
}

a.green-btn {
  background: rgba(0, 107, 111, 0.75);
  border-radius: 10px;
  width: 182px;
  height: 43px;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Lato-Medium";
  margin-bottom: 30px;
  margin-right: 15px;
}
a.green-btn:hover {
  background: rgba(0, 107, 111, 0.85);
}

button.green-btn {
  background: rgba(5, 107, 110, 0.75);
  border-radius: 10px;
  width: 182px;
  height: 43px;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Lato-Medium";
}

button.green-btn2 {
  /* color: rgba(5, 107, 110, 0.75); */
  color: #00babe;
  background-color: #ffffff;
  font-size: 14px;
}

button.green-btn3 {
  background: #4cbf26;
  color: #ffffff;
}

button.green-btn32 {
  color: #00babe;
  background-color: #ffffff;
  border: 1px solid #00babe;
}

button.green-btn:hover {
  background: rgba(0, 107, 111, 0.85);
}

button.red-btn {
  background: #f05b68;

  border-radius: 10px;
  width: 182px;
  height: 43px;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Lato-Medium";
}
button.red-btn:hover {
  background: red;
}

button.grey-btn {
  background: grey;
  border-radius: 10px;
  width: 182px;
  height: 43px;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Lato-Medium";
}

a.light-share-btn {
  background: rgba(0, 186, 190, 0.5);
  border-radius: 10px;
  width: 182px;
  height: 43px;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Lato-Medium";
  margin-bottom: 30px;
}
a.light-share-btn:hover {
  background: rgba(0, 186, 190, 0.6);
}
.recording-buttons {
  display: flex;
  align-items: center;
}
span.activity-color {
  color: rgba(38, 98, 131, 0.75);
}
.review-recording .massage-content {
  font-size: 22px;
  line-height: 26px;
}
.snr-box-inner.left {
  width: 50%;
  text-align: center;
  border-right: 2px solid #c1c1c1;
  padding-right: 15px;
}
.snr-box-inner.right {
  width: 50%;
  text-align: center;
  padding-left: 15px;
}
h4.green-text {
  font-family: "Lato-Medium";
  font-size: 24px;
  line-height: 29px;
  color: rgba(50, 205, 50, 0.8);
}
h4.dark-green-text {
  font-family: "Lato-Medium";
  font-size: 24px;
  line-height: 29px;
  color: rgba(38, 98, 131, 0.75);
}
.inbox-body.snr-quality {
  display: flex;
}
.snr-img {
  width: 100%;
}
@media only screen and (max-width: 1709px) and (min-width: 1200px) {
  .rocording-stats-box {
    flex-direction: column;
  }
  .snr-box-inner.left {
    padding-right: 7px;
  }
  .snr-box-inner.right {
    padding-left: 7px;
  }
  .recording-buttons {
    margin-top: 25px;
  }
  .review-recording .massage-content {
    font-size: 14px;
    line-height: 20px;
  }
  h4.dark-green-text {
    font-size: 20px;
    line-height: 25px;
  }
  h4.green-text {
    font-size: 20px;
    line-height: 25px;
  }
  .ls-patient-status {
    font-size: 20px;
    line-height: 25px;
  }
  .ls-dor {
    font-size: 18px;
    line-height: 22px;
  }
  .review-recording .left-sidebar .navbar-expand-lg {
    height: 88% !important;
  }
}
@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .rocording-stats-box {
    flex-direction: column;
  }
  .ls-patient-status {
    font-size: 20px;
    line-height: 25px;
  }
  .ls-dor {
    font-size: 18px;
    line-height: 22px;
  }
  .recording-buttons {
    margin-top: 30px;
  }
  .review-recording .massage-content {
    font-size: 15px;
    line-height: 19px;
  }
  h4.green-text {
    font-size: 18px;
    line-height: 23px;
  }
  .snr-box-inner.left {
    padding-right: 5px;
  }
  h4.dark-green-text {
    font-size: 18px;
    line-height: 23px;
  }
  .snr-box-inner.right {
    padding-left: 5px;
  }
  .review-recording .left-sidebar .navbar-expand-lg {
    height: 90% !important;
  }
}
@media (min-width: 992px) {
  .review-recording .left-sidebar .navbar-expand-lg {
    height: 92%;
    padding: 0;
  }
  .review-recording .left-sidebar {
    padding-right: 0px;
    flex: 0 0 13.666667%;
    max-width: 13.666667%;
  }
  .review-recording .right-boxes.col-lg-4 {
    flex: 0 0 36.333333%;
    max-width: 36.333333%;
    padding-right: 40px;
  }
}
@media (max-width: 991px) {
  .rocording-stats-box {
    flex-direction: column;
    margin-top: 15px;
  }
  .recording-buttons {
    margin-top: 30px;
  }
  .left-section-header {
    margin-left: 0;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
  .review-recording .massage-content {
    font-size: 16px;
    line-height: 20px;
  }
  .inbox-body.snr-quality {
    flex-direction: column;
  }
  .snr-box-inner.left {
    width: 100%;
    text-align: center;
    border-right: 0px;
    padding-right: 0;
    border-bottom: 2px solid #c1c1c1;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .snr-box-inner.right {
    width: 100%;
    padding-left: 0;
  }
}

.wrapper-profile-picture {
  padding: 100px;
}

.image--cover {
  width: auto;
  height: 100%;
  border-radius: 50%;
  margin: 20px;
  max-width: 137px;
  object-fit: contain;
  object-position: center right;
}
.im {
  transition: transform 0.2s;
  margin: 0 auto;
}

.im:hover {
  transform: scale(1.1);
}

/* css paymentpage */
.border {
  background-color: rgba(0, 186, 190, 0.6);
}

.card-header .icons .fa-cc-visa {
  color: #ffb85f;
}
.card-header .icons .fa-cc-discover {
  color: #027878;
}
.card-header .icons .fa-cc-amex {
  color: #eb4960;
}
.card-body label {
  font-size: 14px;
}
.iconcolor {
  color: green;
  font-size: 20px;
  margin-right: 10px;
}
.crossicon {
  color: red;
  font-size: 20px;
  margin-right: 10px;
}
.card2 {
  width: 600px;
  padding: 10px;
  border-radius: 20px;
  background: #fff;
  border: none;
  height: 330px;
  position: relative;
}

@media (max-width: 991px) {
  .card2 {
    width: 370px;
    margin: 0px;
  }
  .d-flex {
    display: block !important;
    justify-content: left !important;
  }
  .card1 {
    width: 350px !important;
  }
}
.card1 {
  width: 650px;
  padding: 10px;
  border-radius: 20px;
  background: #fff;
  border: none;
  height: 334px;
  position: relative;
}
/* body {
  background: #eee;
} */

.mobile-text {
  color: #989696b8;
  font-size: 15px;
}

.form-control {
  margin-right: 12px;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ff8880;
  outline: 0;
  box-shadow: none;
}

.cursor {
  cursor: pointer;
}
.textverification {
  color: #34a7aa;
}
.opt {
  display: block !important;
}
.ropt {
  display: block !important;
}

@media screen and (max-width: 667px) {
  .cardresponsive {
    width: 100%;
  }
}
.bgbutton :hover {
  background-color: #00ac69;
}
.paymentprice {
  font-size: 50px;
}
.modal-header {
  padding-left: 38%;
}
.btncolour {
  background-color: white;
  font-size: 20px;
  outline: none;
  margin-right: 35%;
  border: none;
}
.btncolour :hover {
  background-color: none;
}

.eegFileBtn {
  margin-left: auto;
  border-radius: 10%;
  background: rgba(1, 149, 153, 0.75);
}
.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 10px;
  /* border: solid; */
  border: 1px solid #c5ccd6 !important;
  padding: 11px;
}
