.expansion-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.list-table {
  background-color: #fcfcfc;
  border-radius: 10px;
  /* overflow-y: auto; */
  margin-top: 10px;
}

.table tbody tr[role="row"]:hover {
  background-color: #bee5eb;
}
.border-top-grey {
  border-top: 3px solid rgb(226, 226, 226) !important;
  border-bottom: 1px solid rgb(226, 226, 226) !important;
}
/* Prevent hover effect on expanded row and its nested table */
.table tbody tr.expanded-row:hover {
  background-color: transparent;
}

/* Remove hover effect from header */
.table-expanded thead tr:hover {
  background-color: transparent !important;
}

/* Change background color on row hover */
.table-expanded tbody tr:hover {
  background-color: #bee5eb;
}

/* .table-expanded tbody tr {
  border-left: 1px solid #e2e2e2 !important;
} */

/* Adjust font size for both headers and rows */
.table-expanded thead th {
  font-size: 14px;
  font-weight: bold;
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.table-expanded tbody td {
  font-size: 14px;
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

/* Add padding to the cells */
.table-expanded th,
.table-expanded td {
  padding: 6px;
}

/* General button styling */
.action-button,
.action-button-expanded {
  visibility: hidden; /* Default hidden for buttons */
  color: white;
  background-color: #04203d;
  border: 1px solid #04203d;
  border-radius: 10px;
  padding: 2px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 5px;
  font-weight: bold;
  width: auto;
  height: auto;
}

/* Specific styling for delete button */
.action-button.delete,
.action-button-expanded.delete {
  color: red; /* Red text */
  background-color: white; /* White background */
  border: 1px solid red; /* Red border */
  font-weight: bold;
}

/* Expanded state visibility */
.action-button-expanded,
.action-button-expanded.delete {
  visibility: visible;
}

/* Hover to show action button */
.table tbody tr:hover .action-button {
  visibility: visible;
}

.pagination {
  margin-right: 20px;
}
.page-btn {
  text-decoration: underline;
  color: #264361;
  font-style: bold;
  cursor: pointer;
}

.input-container {
  position: relative;
}

.input-label {
  margin: 0 4px;
  color: #0c2540;
}

.page-search-input {
  border: 1px solid #0c2540 !important;
  border-radius: 10px !important;
  outline: none;
  width: 40px;
  padding: 4px 8px;
  display: none;

  /* Disable number spinner */
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;

  /* Align text to center */
  text-align: center;
}

.input-container:hover .page-search-input {
  display: block;
}
.input-container:hover .input-label {
  display: none;
}

.page-search-input:focus {
  border: 1px solid #0c2540 !important;
  border-radius: 10px !important;
  outline: none;
}
/* Remove spinner buttons in different browsers */
.page-search-input::-webkit-outer-spin-button,
.page-search-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.patient-row {
  font-size: 15px;
}
.patient-row-head {
  font-weight: bold;
  font-size: 15px;
}

.for-table-col {
  background-color: transparent;
}

.patients-dot-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 220px;
  background-color: white;
  border: 1px solid #d0d3d4;
  z-index: 10;
  margin-right: 10px;
}

.add-circle-btn {
  margin-top: 10px !important;
  font-size: 50px;
  color: #1976d2;
  cursor: pointer;
}
