.grid-view {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.grid-item-name {
    text-transform: capitalize;
}

.grid-item {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.grid-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}