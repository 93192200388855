.section__Jobs-styledContent {
    font-family: sans-serif;
    text-align: left;
    color: #8892b0;
  }
  
  .section__Jobs-styledContent h4 {
    color: #ccd6f6;
    font-size: 22px;
    font-weight: 500;
  }
  
  .section__Jobs-styledContent h5 {
    font-size: 13px;
    font-family: "Roboto Mono", monospace;
    letter-spacing: 0.05em;
    margin-bottom: 30px;
    color: #64ffda;
  }
  
  .section__Jobs-styledContent p {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
  }
  
  .section__Jobs-detail::before {
    content: "▹";
    position: absolute;
    left: 0px;
    color: #64ffda;
  }
  