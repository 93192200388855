

.img {
  margin-bottom: 20px;
  width: 160px;
  height: 50px;
}

.message {
  color: black;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  word-wrap: break-word;
  margin-bottom: 20px;
}

.reset-button {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  background-color: #092540;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #0c3857;
}

.border-line {
  width: 100%;
  height: 1px;
  background-color: #d0d3d4;
  margin: 20px 0;
}

.footer {
  color: black;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.6px;
  word-wrap: break-word;
  margin-top: 20px;
}

@media (max-width: 480px) {
  .reset-button {
    font-size: 14px;
  }

  .message {
    font-size: 14px;
  }

  .footer {
    font-size: 12px;
  }
}