.list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 10px; */
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.dashboard-headline h2 {
  font-size: 28px;
  color: #494646 !important;
  margin-top: 5px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
  text-decoration: underline #777474;
}

.left-header {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.left-header h2 {
  /* font-size: 32px; */
  font-size: 28px;
  color: #1a1a1a !important;
  margin: 0;
  margin-right: 80px;
}

.right-header {
  display: flex;
  align-items: center;
  gap: 30px;
}

.view-container {
  background-color: #e1e5e9;
  display: flex;
  gap: 10px;
  width: max-content;
  border-radius: 8px;
  padding: 5px;
}

.view-container img {
  cursor: pointer;
  width: 22px;
  padding: 3px;
  border-radius: 5px;
}

.active-view {
  background-color: #fcfcfc;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 17px;
  cursor: pointer;
  margin-left: auto;
}

.search-bar {
  border: 2px solid #e5e5e5;
  border-radius: 8px;
  background-color: white !important;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 5px;
}

.search-bar input {
  height: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  margin-left: 8px;
  font-size: 18px;
  color: #929292;
  width: 300px;
}

.search-bar input::placeholder {
  color: #929292;
}

.search-bar input:focus {
  outline: none !important;
  border: none !important;
}
@media (max-width: 880px) {
  .right-header {
    margin-top: 20px;
  }
  .search-bar {
    width: 100%;
  }
  .left-header {
    width: 100%;
  }
}
