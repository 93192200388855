* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  /* height: 70px; */
  height: auto;
  flex-wrap: wrap;
  background-color: #fcfcfc;
  padding: 20px;
  display: flex;
  align-items: center;
  /* border: 1px solid #000;
    /* border-top: none; */
  /* margin-bottom: 5px; */
  /* box-shadow: 0 4px 4px 0px rgba(0,0,0,0.2); */
}

.logo-container {
  width: 140px;
}

.logo-container img {
  width: 100%;
}

.navigation {
  display: flex;
  gap: 20px;
  list-style: none;
  margin: 0;
  margin-left: 30px;
  position: relative;
  padding: 0;
}

.navigation li {
  padding: 2px 5px;
  padding-bottom: 0;
  cursor: pointer;
  transition: all 0.15s;
  border-bottom: 2px solid transparent;
}

.navigation li:hover {
  /* background-color: #D9EDEE; */
  border-bottom: 2px solid #000;
}

.active-nav {
  border-bottom: 2px solid #000 !important;
}

.user-initials-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #888383;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.clinic-dropdown {
  position: absolute;
  top: 40px;
  left: 0;
  width: 220px;
  height: fit-content;
  background-color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d3d4;
  border-radius: 5px;
  padding: 10px 0;
}

.research-dropdown {
  position: absolute;
  top: 40px;
  left: 75px;
  width: 220px;
  height: fit-content;
  background-color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d3d4;
  border-radius: 5px;
  padding: 10px 0;
}

.dropdown-item-wrapper {
  position: relative;
}

.sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white;
  border: 1px solid #d0d3d4;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 101;
  border-radius: 5px;
  width: 220px;
  display: flex;
  flex-direction: column;
}

.sub-menu .dropdown-item {
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 955px) {
  .sub-menu {
    position: absolute;
    top: 54px;
    left: 0px;
  }
}

.dropdown-item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.15s;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #d9edee;
}

.dropdown-item > p {
  margin: 0;
  font-size: 18px;
}

.dropdown-item > img {
  width: 24px;
  height: 24px;
}

.header-user-icon {
  margin-left: auto;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.user-dropdown {
  position: absolute;
  right: 0;
  top: 55px;
  /* width: 260px; */
  background-color: #fcfcfc;
  z-index: 100;
  border-radius: 3px;
  border: 1px solid #d0d3d4;
  padding-bottom: 5px;
}

.user-dropdown-header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.user-dropdown-header img {
  height: 40px;
}

.user-dropdown-header div {
  display: flex;
  flex-direction: column;
}

.header-username {
  font-size: 15px;
  margin: 0;
}

.header-useremail {
  font-size: 12px;
}
.header-dropdownItems {
  display: flex;
  flex-direction: column;
}

.divider {
  height: 1px;
  background-color: #d0d3d4;
  margin: 5px 0;
}

@media (max-width: 575px) {
  .header {
    padding: 9px;
  }
  .patient-list {
    padding: 20px 9px !important;
  }
}

.delete-clr {
  color: red;
}
