.recording2 {
  background-color: #f7f8f9;
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
  gap: 30px;
}

.recording-graph-panel {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.info-container {
  display: flex;
}

.personal-info {
  display: flex;
  flex-direction: column;
  width: 300px;
  font-weight: normal;
}

.personal-info h1 {
  font-size: 32px;
}

.info-wrapper {
  display: flex;
  flex-direction: column;
}

.info-wrapper .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.info-wrapper .info p {
  flex: 1;
  text-align: left;
}

.btns-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 30px;
  gap: 10px;
}

.button {
  width: 10rem;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 10px;
  border: 1px solid transparent;
}

.btn-invert-rec {
  color: #092540;
  border: 1px solid #092540 !important;
  background-color: transparent;
}

.btn-default-rec {
  background-color: #092540;
  color: #fff;
}

.eeg-graph-panel {
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  border-radius: 8px;
}

.eeg-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-stats {
  display: flex;
  align-items: center;
  gap: 30px;
}

.lef-hff {
  display: flex !important;
}

.header-stat {
  font-size: 18px;
  display: flex;
}

.header-stat p div {
  color: #00babe;
}

.header-view-container {
  background-color: #e1e5e9;
  padding: 5px;
  display: flex;
  gap: 10px;
  border-radius: 8px;
}

.header-view-container img {
  padding: 2px;
  border-radius: 5px;
  width: 24px;
  cursor: pointer;
}

.header-active-view {
  background-color: #fcfcfc;
}

.eeg-graph-controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.eeg-graph-controls img {
  cursor: pointer;
}

.control-button {
  border: none;
  background-color: transparent;
}

.control-button:focus {
  border: none;
}

.qeeg-graph-panel {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.qeeg-graph-panel p span {
  color: #00babe;
}

.qeeg-graph {
  background-color: #fcfcfc;
  border-radius: 8px;
  padding: 10px;
}

.recording-sidebar {
  background-color: #fcfcfc;
  width: 300px;
  padding: 20px 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tabs {
  display: flex;
  align-self: center;
  border: 2px solid #092540;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 40px;
}

.tab {
  font-size: 12px;
  padding: 5px 15px;
  cursor: pointer;
}

.active-tab {
  background-color: #092540;
  color: #fcfcfc;
}

.tabs-list {
  width: 100%;
}

.tabs-list-item {
  padding-bottom: 2px;
  border-bottom: 1px solid #092540;
  font-size: 18px;
}

.tabs-list-item:not(:last-child) {
  margin-bottom: 10px;
}

@media (max-width: 1050px) {
  .recording2 {
    flex-direction: column;
  }
  .recording-sidebar {
    width: 100%;
  }
}

@media (max-width: 880px) {
  .eeg-header {
    flex-direction: column;
  }
  .header-view-container {
    margin-left: auto;
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .info-container {
    flex-direction: column;
  }
  .btns-container {
    margin-left: 0;
    margin-top: 20px;
  }

  .header-stats {
    flex-wrap: wrap;
    row-gap: 10px;
  }
}

.responsive-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  max-height: 450px;
}

.heatmap-container {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heatmap-plot {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* ---------------------- */
/* .heatmap-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  /* .heatmap-plot {
    width: 100%;
    height: 100%;
  }

  .plot-container {
    width: 100%;
    height: 100%;
  } */

.fullscreen-container {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 10px 5px;
  border-radius: 8px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  margin-bottom: 50px;
}

.heatmap-plot {
  width: 100%;
  height: 100%;
}

.plot-container {
  width: 100%;
  height: 100%;
}
.user-select-none .svg-container {
  left: 9px;
}

.colorbar {
  color: #00babe;
  font-weight: bold;
  font-size: 16px;
}

.linear-loader {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 2%;
  padding-right: 2%;
}

.pwelch-hide {
  visibility: hidden;
}

.pwelch-show {
  visibility: visible;
}

.btn-back {
  background: transparent;
  color: black;
  font-size: 22px;
  border: none;
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  outline: none;
}

.graph-loc {
  display: flex;
  align-items: center;
}

.location-btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.loc-btn:disabled {
  border: none;
  outline: none;
  cursor: not-allowed;
}

.loc-btn {
  font-size: 16;
  font-weight: bold;
  color: #00babe;
}
.loc-name {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 16px;
}

.popup {
  position: absolute;
  background-color: #fff;
  border: 2px solid #ffffff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 200px;
}

.popup h3 {
  margin: 0;
  font-size: 16px;
}

.popup label {
  display: flex;
}

.error {
  margin-left: 7px;
}

.popup .min-label {
  margin-right: 4px;
}

.popup input {
  margin-left: 10px;
  width: 120px;
  border: 1px solid #ccc;
  border-radius: 10px !important;
}
.popup input:focus {
  outline: none !important;
}

.popup .btn-set {
  margin-left: 10px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: #092540;
  color: #fff;
  width: 10rem;
}

.back-info-btn {
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.rec-data {
  margin-left: 2rem;
}

.rec-data1 {
  color: #b60b0b;
  margin-left: 2rem;
}
