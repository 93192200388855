.res-m {
  margin-right: 13px;
}
@media (max-width: 991px) {
  .res-m {
    margin-right: 0 !important;
  }
}

.select-options {
  line-height: 1.2;
}
select.form-control:not([size]):not([multiple]) {
  height: auto;
}

.custom-dropdown {
  height: 50px;
  width: 200px;
}

.password-field {
  position: relative;
}

.password-checker {
  position: absolute;
  right: -250px;
  top: -20%;
  /* border: 1px solid #1A1A1A; */
  box-shadow: 0px 0px 10px #888888;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;

  /* position: relative;
    padding: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
}
.password-checker::before {
  border: none !important;
}
.password-checker::after {
  border: none !important;
}

.check-list {
  display: flex;
  flex-direction: column;
}

.check {
  display: flex;
  align-items: center;
  gap: 3px;
  color: black;
  font-size: 14px;
  margin: 4px 0;
  /* text-decoration: line-through; */
}

.check > svg {
  width: 14px;
  height: 14px;
}

.strength-container {
  display: flex;
  flex-direction: column;
  margin-left: 17px;
  margin-top: 5px;
}

.strength-container > .instructions {
  font-size: 12px;
  margin-bottom: 2px;
}

.strength-container > .strengths {
  display: flex;
  gap: 3px;
  align-items: center;
}

.strength {
  width: 50px;
  height: 10px;
  border-radius: 5px;
  background-color: #cccccc;
}

.weak {
  color: #ff4f4f;
  font-weight: 900;
}

.medium {
  color: #ff964f;
  font-weight: 900;
}

.strong {
  color: #11ea9c;
  font-weight: 900;
}

.weak-bg {
  background-color: #ff4f4f;
}

.medium-bg {
  background-color: #ff964f;
}

.strong-bg {
  background-color: #11ea9c;
}
.password-checker {
  border: 1px solid transparent !important;
}
/* .arrow {
    width: 20px;
    height: 20px;
    position: absolute;
    left: -17px;
    top: 50%;
    transform: translate(0%, -50%);
} */
.arrow {
  display: none;
}

.eye-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 37px;
  top: 45px;
  cursor: pointer;
}
.line-through {
  text-decoration: line-through;
  color: #97999c !important;
}
.div-image {
  margin-bottom: 20px;
}
.margins-b {
  margin-bottom: 14px;
  color: rgb(20, 20, 20);
}
